var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "text-dark d-block mt-1", on: { click: _vm.routerBack } },
    [_c("i", { staticClass: "fa fa-chevron-left" }), _vm._v(" Back\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }