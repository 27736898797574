var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: this.loading,
          expression: "this.loading"
        }
      ],
      staticClass: "refresh-container",
      style: _vm.getStyleObject
    },
    [_c("div", { staticClass: "progress-line" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }