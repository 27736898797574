<template>
    <button type="button" class="btn btn-sm btn-primary" @click="routerBack">
        <span class="fa fa-chevron-left"></span> Back
    </button>
</template>


<script>
export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        routerBack() {
            this.$router.go(-1)
        }
    }
}
</script>


<style scoped>

</style>
