var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "span",
        {
          ref: "trigger",
          staticClass: "tooltip-trigger",
          on: { mouseenter: _vm.toggle, mouseleave: _vm.toggle }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" }, on: { "after-leave": _vm.doDestory } },
        [
          _vm.show
            ? _c(
                "div",
                {
                  ref: "popover",
                  class: _vm.tooltipClass,
                  attrs: { role: "tooltip" }
                },
                [
                  _c("div", { staticClass: "tooltip-arrow" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tooltip-content" },
                    [
                      _vm._t("content", [
                        _c("div", [_vm._v(_vm._s(_vm.content))])
                      ])
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }