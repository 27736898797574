import Vue from "vue";
import Vuex from "vuex";

import Filterable from './modules/Common/Filterable';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        Filterable
    },
    strict: debug,
});
