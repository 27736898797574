<template>
    <div>
        <gmap-map
            :zoom="7"
            :center="mapCenter"
            style="width:100%;  height: 600px;"
        >
            <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
            >
            </gmap-info-window>
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                @click="markerClicked(m,index)"
            ></gmap-marker>
        </gmap-map>
    </div>
</template>

<script>
export default {
    props: {
        markers: {
            type: Array,
            default: () => []
        },
        marker: {
            type: Object,
            default: () => null
        },
        infoType: {
            type: String,
            default: () => 'event'
        },
    },
    data() {
        return {
            center: {
                lat: 44.58504322216937,
                lng: -100.3781779598541
            },
            currentSelectedMarker: null,
            infoWindowPos: null,
            infoWinOpen: false,
            infoOptions: {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }
        };
    },
    mounted() {
        this.locateGeoLocation();
    },
    computed: {
        mapCenter() {
            return (this.marker && this.marker.hasOwnProperty("position"))? this.marker.position:this.center;
        }
    },
    methods: {
        locateGeoLocation: function() {
            navigator.geolocation.getCurrentPosition(res => {
                this.center = {
                    lat: res.coords.latitude,
                    lng: res.coords.longitude
                };
            });
        },
        markerClicked: function (marker, idx){
            if(this.infoType === "event") {
                this.$emit("markerClicked", marker);
            } else {
                this.toggleInfoWindow(marker, idx);
            }
        },
        toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = '<div id="content">'+
            '<h5 id="firstHeading" class="firstHeading">'+marker.title+'</h5>'+
            '<div id="bodyContent">'+
            '<p>'+marker.full_address+'</p>'+
            '</div>'+
            '</div>';

            //check if its the same marker that was selected if yes toggle
            if (this.currentSelectedMarker === idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentSelectedMarker = idx;

            }
        }
    }
};
</script>

<style scoped>

</style>
