import Vue from 'vue'
import Bar from './Bar.vue'

// This exports the plugin object.
export default {
    // The install method will be called with the Vue constructor as
    // the first argument, along with possible options
    installed: false,
    bar: null,
    install (Vue, options) {
        if (this.installed) return;
        this.bar = new Vue(Bar).$mount();
        document.body.appendChild(this.bar.$el);
        Vue.component('loading-bar', Bar);
        Vue.prototype.$loadingBar = this.bar;
    }
}
