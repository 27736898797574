var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "vue-btn",
      class: _vm.getBackgroundClass,
      attrs: { type: _vm.getType }
    },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c("div", { class: _vm.getSpinnerClass })
      ]),
      _vm._v(" "),
      _vm.showSlot ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }