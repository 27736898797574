var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", {
      staticClass: "switch",
      class: [{ "switch-checked": _vm.value, "switch-disabled": _vm.disabled }],
      attrs: { tabindex: _vm.disabled ? -1 : _vm.tabindex },
      on: {
        click: _vm.toggleSwitch,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.toggleSwitch($event)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }