var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn btn-sm btn-primary",
      attrs: { type: "button" },
      on: { click: _vm.routerBack }
    },
    [_c("span", { staticClass: "fa fa-chevron-left" }), _vm._v(" Back\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }