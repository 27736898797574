<template>
    <div class="refresh-container" v-show="this.loading" :style="getStyleObject">
        <div class="progress-line"></div>
    </div>
</template>

<script>
    export default {
        props:{
            loading: false
        },
        computed:{
            getStyleObject () {
                if(this.loading)
                    return {display: "block"};
                return {display: "none"};
            }
        }
    }
</script>

<style scoped>

</style>