var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gmap-map",
        {
          staticStyle: { width: "100%", height: "600px" },
          attrs: { zoom: 7, center: _vm.mapCenter }
        },
        [
          _c("gmap-info-window", {
            attrs: {
              options: _vm.infoOptions,
              position: _vm.infoWindowPos,
              opened: _vm.infoWinOpen
            },
            on: {
              closeclick: function($event) {
                _vm.infoWinOpen = false
              }
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.markers, function(m, index) {
            return _c("gmap-marker", {
              key: index,
              attrs: { position: m.position, clickable: true },
              on: {
                click: function($event) {
                  return _vm.markerClicked(m, index)
                }
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }