var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-6 text-center" },
        [
          _c("h1", { staticClass: "text-danger" }, [
            _vm._v("404 - Page not found")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Page you are  looking for might have been removed,had it's name changed or temporarily unavailable"
            )
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _vm._v("Go Back To Home")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }