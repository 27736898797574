<template>
    <div class="card shadow mb-4 filterable">
        <slot name="card-header">
            <div class="card-header py-3">
                <slot name="title"></slot>
                <slot name="filter-nav"  :sortingClass="sortingClass" :sortData="sortData"></slot>
            </div>
        </slot>
        <slot name="loader" :loading="loading">
            <loader-bar :loading="loading"></loader-bar>
        </slot>
        <div class="card-body">
            <slot name="alert">
                <div class="alert alert-danger mt-1" v-if="hasErrors">
                    <i class="fa fa-exclamation-triangle"></i> OOPS! Error Occurred.
                </div>
            </slot>
            <slot name="filterable-selectors">
                <div class="pb-3 collapse" id="collapseFilter">
                    <div class="row">
                        <slot name="exportables">
                            <template v-if="!_.isEmpty(exportable) && exportEndpoint && showExportable && !hide">
                                <div class="col">
                                    <div class="filters-title pt-4 pb-2 mb-2">
                                        <span>Select fields to export of the following</span>
                                    </div>
                                </div>
                            </template>
                        </slot>
                        <slot name="filterables">
                            <div class="col">
                                <div class="filters-title pt-1 pb-2 mb-2">
                                    <span>Records matching</span>
                                    <select v-model="query.filter_match" class="form-control form-control-sm">
                                        <option value="and">All</option>
                                        <option value="or">Any</option>
                                    </select>
                                    <span>Of the following</span>
                                    <a class="btn btn-sm btn-info text-white" v-if="!_.isEmpty(exportable) && exportEndpoint && !hide" @click="showExportable = !showExportable">
                                        <span v-if="showExportable"><i class="fas fa-box"></i> Hide</span>
                                        <span v-if="!showExportable"><i class="fas fa-box-open"></i> Show</span>
                                    </a>
                                </div>
                                <div class="filters filter-conditions">
                                    <div class="filters-item" v-for="(f, i) in filterCandidates">
                                        <div class="filters-column">
                                            <select class="form-control" @change="onColumnSelect(f, i, $event)">
                                                <option value="">Select Filter</option>
                                                <optgroup v-for="group in filterGroups" :label="group.title">
                                                    <option v-for="x in group.filters" :value="JSON.stringify(x)"
                                                            :selected="f.column && x.name === f.column.name">
                                                        {{x.title}}
                                                    </option>
                                                </optgroup>
                                            </select>
                                            <input-error :errors="errors" :name="`f.${i}.column`"></input-error>
                                        </div>
                                        <div class="filters-operator" v-if="f.column">
                                            <select class="form-control" @change="onOperatorSelect(f, i, $event)">
                                                <option v-for="y in fetchOperators(f)" :value="JSON.stringify(y)"
                                                        :selected="f.operator && y.name === f.operator.name">
                                                    {{y.name}}
                                                </option>
                                            </select>
                                            <input-error :errors="errors" :name="`f.${i}.operator`"></input-error>
                                        </div>
                                        <template v-if="f.column && f.operator">
                                            <div class="filters-full" v-if="f.operator.component === 'single'">
                                                <input type="text" class="form-control" :placeholder="f.placeholder"
                                                       v-model="f.query_1">
                                                <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                            </div>

                                            <div class="filters-full" v-if="f.operator.component === 'lookup'">
                                                <x-tag-input :value="f.query_1"
                                                             :resource="f.column.resource"
                                                             :column="f.column.column || f.column.name"
                                                             :name="f.column.column || f.column.name"
                                                             @input="value => { f.query_1 = value }" multiple>
                                                </x-tag-input>
                                                <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                            </div>

                                            <div class="filters-full" v-if="f.operator.component === 'static_lookup'">
                                                <v-select
                                                    :value="f.query_1"
                                                    @input="value => { f.query_1 = value }"
                                                    :options="setOptions(f.column.options)"
                                                    :label="'name'"
                                                    multiple>
                                                </v-select>
                                                <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                            </div>
                                            <template v-else-if="f.operator.component === 'dual'">
                                                <div class="filters-query_1">
                                                    <input type="number" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_1">
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                                <div class="filters-query_2">
                                                    <input type="number" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_2">
                                                    <input-error :errors="errors" :name="`f.${i}.query_2`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'datetime_1'">
                                                <div class="filters-query_1">
                                                    <input type="number" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_1">
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                                <div class="filters-query_2">
                                                    <select class="form-control" v-model="f.query_2">
                                                        <option value="hours">{{'hours'}}</option>
                                                        <option value="days">{{'days'}}</option>
                                                        <option value="months">{{'months'}}</option>
                                                        <option value="years">{{'years'}}</option>
                                                    </select>
                                                    <input-error :errors="errors" :name="`f.${i}.query_2`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'datetime_2'">
                                                <div class="filters-query_2">
                                                    <select class="form-control" v-model="f.query_1">
                                                        <option value="yesterday">{{'yesterday'}}</option>
                                                        <option value="today">{{'today'}}</option>
                                                        <option value="tomorrow">{{'tomorrow'}}</option>
                                                        <option value="last_month">{{'last_month'}}</option>
                                                        <option value="this_month">{{'this_month'}}</option>
                                                        <option value="next_month">{{'next_month'}}</option>
                                                        <option value="last_year">{{'last_year'}}</option>
                                                        <option value="this_year">{{'this_year'}}</option>
                                                        <option value="next_year">{{'next_year'}}</option>
                                                    </select>
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'datetime_3'">
                                                <div class="filters-query_1">
                                                    <input type="date" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_1">
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'datetime_4'">
                                                <div class="filters-query_1">
                                                    <input type="number" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_1">
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                                <div class="filters-query_2">
                                                    <select class="form-control" v-model="f.query_2">
                                                        <option value="hours">{{'hours'}} {{'ago'}}</option>
                                                        <option value="days">{{'days'}} {{'ago'}}</option>
                                                        <option value="months">{{'months'}} {{'ago'}}</option>
                                                        <option value="years">{{'years'}} {{'ago'}}</option>
                                                    </select>
                                                    <input-error :errors="errors" :name="`f.${i}.query_2`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'datetime_5'">
                                                <div class="filters-query_1">
                                                    <input type="date" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_1">
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                                <div class="filters-query_2">
                                                    <input type="date" class="form-control" :placeholder="f.placeholder"
                                                           v-model="f.query_2">
                                                    <input-error :errors="errors" :name="`f.${i}.query_2`"></input-error>
                                                </div>
                                            </template>
                                            <template v-else-if="f.operator.component === 'toggle'">
                                                <div class="filters-query_1">
                                                    <div class="filter-toggle">
                                                        <x-switch v-model="f.query_1"></x-switch>
                                                    </div>
                                                    <input-error :errors="errors" :name="`f.${i}.query_1`"></input-error>
                                                </div>
                                            </template>
                                        </template>
                                        <div class="filters-remove-wrap" v-if="f">
                                            <a class="filters-remove text-danger" :disabled="loading" @click="removeFilter(f, i)">
                                                <i class="fa fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="filters-control" v-if="appliedFilters.length || showFilterControls">
                                    <div class="filters-control-item">
                                        <a :disabled="loading" class="btn btn-sm btn-outline-secondary" @click="addFilter"><i class="fa fa-plus"></i></a>
                                    </div>
                                    <div class="filters-control-item" v-if="appliedFilters.length">
                                        <div class="filters-control-item-line"></div>
                                        <a :disabled="loading" class="btn btn-sm btn-outline-secondary" @click="resetFilter"><i class="fa fa-sync-alt"></i></a>
                                    </div>
                                    <div class="filters-control-item">
                                        <div class="filters-control-item-line"></div>
                                        <a class="btn btn-sm btn-outline-secondary" :disabled="loading" @click="applyFilter"><i class="fa fa-filter"></i></a>
                                    </div>
                                    <div class="filters-control-item" v-if="exportEndpoint && appliedFilters.length">
                                        <div class="filters-control-item-line"></div>
                                        <a class="btn btn-sm btn-outline-secondary" :disabled="loading" @click="applyExport"><i class="fa fa-file-export"></i></a>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </slot>
            <div  class="position-relative">
                <slot name="content" :items="items" :remove="remove" :sortingClass="sortingClass" :sortData="sortData">
                </slot>
                <slot name="paging" :items="items" :getResults="getResults">
                    <div class="mt-4" v-if="!_.isEmpty(items.data)" >
                        <pagination :pager="items.meta" @pagination-change-page="getResults"></pagination>
                    </div>
                </slot>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import pagination from './Pagination';
    export default {
        components: {
            pagination
        },
        props: {
            endpoint: String,
            exportEndpoint: {
                type: String,
                default: null
            },
            sortable: {
                type: Array,
                default: []
            },
            exportable: {
                type: Array,
                default: () => []
            },
            params:{
                type: Object,
                default: {}
            },
            filterGroup: Array,
            refresh: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                xprops: {
                    module: 'Filterable'
                },
                query:{
                    filter_match: "and"
                },
                filterCandidates: [],
                appliedFilters: [],
                exportColumns: [],
                appliedColumns: [],
                showExportable:  false,
                hide: true,
                fetch: 1
            }
        },
        mounted() {
            this.setEndpoint(this.endpoint);
            if(this.exportEndpoint){
                this.setExportEndpoint(this.exportEndpoint);
            }
            this.setQuery(this.getQuery());
            this.fetchData();
        },
        destroyed() {
            this.resetState();
        },
        watch: {
            endpoint: {
                handler(endpoint) {
                    if(endpoint) {
                        this.setEndpoint(this.endpoint);
                        this.setQuery(this.getQuery());
                        this.fetchData();
                    }
                }
            },
            refresh: {
                handler(refresh) {
                    if(refresh) {
                        this.fetchData();
                    }
                }
            },
            items: {
                handler(items) {
                    this.$emit("filterableItemsChanged", items);
                }
            },
            params: {
                handler(params) {
                    this.setQuery(this.getQuery());
                    this.fetchData();
                },
                deep: true
            },
            fetch: {
                handler(fetch) {
                    this.setQuery(this.getQuery());
                    this.fetchData();
                }
            }
        },
        computed: {
            ...mapGetters("Filterable", ['errors', 'items', 'loading', 'meta']),
            hasErrors(){
                return !_.isEmpty(this.errors);
            },
            canDelete(){
                return _.isEmpty(this.deleteAction);
            },
            setOptions() {
                return (items) => {
                    return items.map((item) => {
                        return {name: item, value: item}
                    })
                }
            },
            getSortable() {
                return this.sortable.map((item) => {
                    if(typeof item === 'object') {
                        return item
                    }
                    return {name: item, value: item}
                });
            },
            filterGroups() {
                return this.filterGroup.map((item) => {
                    const i = item.filters.map((y) => {
                        if(typeof y.title === 'undefined') {
                            y.title = y.name;
                            return y
                        }
                        return y
                    });
                    item.filters = i;
                    return item
                });
            },
            fetchOperators() {
                return (f) => {
                    return this.availableOperator().filter((operator) => {
                        if(f.column && operator.parent.includes(f.column.type)) {
                            return operator
                        }
                    })
                }
            },
            showFilterReset() {
                return this.appliedFilters.length > 0
            },
            showFilterControls() {
                if(this.filterCandidates.length === 0) return true
                const active = this.filterCandidates.filter((f) => {
                    if(f.column && f.column.name) {
                        return f
                    }
                });

                return active.length > 0
            },
        },
        methods: {
            ...mapActions("Filterable", ['setEndpoint', 'setExportEndpoint', 'fetchData', 'exportData', 'setQuery', 'resetState', 'destroyData']),
            getResults(page = 1) {
                this.$set(this.query, "page", page);
                this.$set(this.$data, "fetch", this.fetch+1);
            },
            sortData(column) {
                this.$set(this.query, "sort_dir", this.query.sort_dir==='asc'?'desc':'asc');
                this.$set(this.query, "sort", column);
                this.$set(this.$data, "fetch", this.fetch+1);
            },
            sortingClass(column) {
                return (column === this.query.sort)? this.query.sort_dir:null;
            },
            remove(id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: 'You won\'t be able to revert this!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    confirmButtonColor: '#dd4b39',
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch(
                            this.xprops.module + '/destroyData',
                            id
                        ).then(result => {
                            this.$awn.success("Item deleted successfully.");
                        }).catch(error => {
                            let message = error.message;
                            this.$awn.info(_.get(error.response.data, 'message', message));
                        });
                    }
                })
            },
            resetFilter() {
                this.$set(this.$data, 'appliedFilters', []);
                this.filterCandidates.splice(0);
                this.addFilter();
                this.$set(this.query, "page", 1);
                this.$set(this.$data, "fetch", this.fetch+1);
            },
            applyFilter() {
                this.$set(this.$data, 'appliedFilters',
                    JSON.parse(JSON.stringify(this.filterCandidates))
                );
                this.$set(this.query, "page", 1);
                this.$set(this.$data, "fetch", this.fetch+1);
            },
            onColumnSelect(f, i, e) {
                const value = e.target.value;
                if(value.length === 0) {
                    this.$set(this.filterCandidates[i], 'column', value);
                    return
                }
                const obj = JSON.parse(value);
                this.$set(this.filterCandidates[i], 'column', obj);

                switch(obj.type) {
                    case 'numeric':
                        this.filterCandidates[i].operator = this.availableOperator()[6]
                        this.filterCandidates[i].query_1 = null;
                        this.filterCandidates[i].query_2 = null;
                        break;
                    case 'lookup':
                    case 'lookup_only':
                        this.filterCandidates[i].operator = this.availableOperator()[11]
                        this.filterCandidates[i].query_1 = [];
                        this.filterCandidates[i].query_2 = null;
                        break;

                    case 'static_lookup':
                        this.filterCandidates[i].operator = this.availableOperator()[21]
                        this.filterCandidates[i].query_1 = [];
                        this.filterCandidates[i].query_2 = null;
                        break;
                    case 'string':
                        this.filterCandidates[i].operator = this.availableOperator()[8]
                        this.filterCandidates[i].query_1 = [];
                        this.filterCandidates[i].query_2 = null;
                        break;
                    case 'toggle':
                        this.filterCandidates[i].operator = this.availableOperator()[23]
                        this.filterCandidates[i].query_1 = 1;
                        this.filterCandidates[i].query_2 = null;
                        break;
                    case 'datetime':
                        this.filterCandidates[i].operator = this.availableOperator()[13]
                        this.filterCandidates[i].query_1 = 28;
                        this.filterCandidates[i].query_2 = 'days';
                        break;
                }
            },
            onOperatorSelect(f, i, e) {
                const value = e.target.value;
                if(value.length === 0) {
                    this.$set(this.filterCandidates[i], 'operator', value);
                    return
                }

                const obj = JSON.parse(value);
                this.$set(this.filterCandidates[i], 'operator', obj);

                this.filterCandidates[i].query_1 = null;
                this.filterCandidates[i].query_2 = null;
                // set default values for query_1 and q2
                switch(obj.name) {
                    case 'includes':
                    case 'not_includes':
                        this.filterCandidates[i].query_1 = [];
                        this.filterCandidates[i].query_2 = null;
                        break;
                    case 'in_the_past':
                    case 'in_the_next':
                    case 'over':
                        this.filterCandidates[i].query_1 = 28;
                        this.filterCandidates[i].query_2 = 'days';
                        break;
                    case 'in_the_peroid':
                        this.filterCandidates[i].query_1 = 'today';
                        break;
                    case 'toggle':
                        this.filterCandidates[i].query_1 = 1;
                        break;
                }
            },
            addFilter() {
                this.filterCandidates.push({
                    column: '',
                    operator: '',
                    query_1: null,
                    query_2: null,
                    placeholder: ''
                });
            },
            removeFilter(f, i) {
                this.filterCandidates.splice(i, 1);
            },
            getQuery() {
                const f = {};
                this.appliedFilters.forEach((filter, i) => {
                    f[`f[${i}][column]`] = filter.column.name;
                    f[`f[${i}][operator]`] = filter.operator.name;

                    if(filter.query_1 && Array.isArray(filter.query_1)) {
                        const list = filter.query_1.map((item) => {
                            return item.value;
                        });

                        f[`f[${i}][query_1]`] = list.join(',,');
                    } else {
                        f[`f[${i}][query_1]`] = filter.query_1;
                    }

                    f[`f[${i}][query_2]`] = filter.query_2;
                });
                return {
                    ...this.params,
                    ...this.query,
                    ...f
                };
            },
            applyExport() {
                this.exportData();
            },
            availableOperator() {
                return [
                    {name: 'equal_to', parent: ['numeric', 'string'], component: 'single'},
                    {name: 'not_equal_to', parent: ['numeric', 'string'], component: 'single'},
                    {name: 'less_than', parent: ['numeric'], component: 'single'},
                    {name: 'greater_than', parent: ['numeric'], component: 'single'},
                    {name: 'less_than_or_equal_to', parent: ['numeric'], component: 'single'},
                    {name: 'greater_than_or_equal_to', parent: ['numeric'], component: 'single'},
                    {name: 'between', parent: ['numeric'], component: 'dual'},
                    {name: 'not_between', parent: ['numeric'], component: 'dual'},
                    {name: 'contains', parent: ['string', 'lookup'], component: 'single'},
                    {name: 'starts_with', parent: ['string', 'lookup'], component: 'single'},
                    {name: 'ends_with', parent: ['string', 'lookup'], component: 'single'},
                    {name: 'includes', parent: ['lookup', 'lookup_only'], component: 'lookup'},
                    {name: 'not_includes', parent: ['lookup', 'lookup_only'], component: 'lookup'},
                    {name: 'in_the_past', parent: ['datetime'], component: 'datetime_1'},
                    {name: 'in_the_next', parent: ['datetime'], component: 'datetime_1'},
                    {name: 'over', parent: ['datetime'], component: 'datetime_4'}, // same as in_the_past
                    {name: 'between_date', parent: ['datetime'], component: 'datetime_5'},
                    {name: 'in_the_peroid', parent: ['datetime'], component: 'datetime_2'},
                    {name: 'equal_to_date', parent: ['datetime'], component: 'datetime_3'},
                    {name: 'is_empty', parent: ['date', 'numeric', 'string', 'datetime', 'lookup'], component: 'none'},
                    {name: 'is_not_empty', parent: ['date', 'numeric', 'string', 'datetime', 'lookup'], component: 'none'},
                    {name: 'includes', parent: ['static_lookup'], component: 'static_lookup'},
                    {name: 'not_includes', parent: ['static_lookup'], component: 'static_lookup'},
                    {name: 'toggle', parent: ['toggle'], component: 'toggle'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>
