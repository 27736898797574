import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from '../components/NotFound';
import Home from '../components/Home';

Vue.use(VueRouter);

const routes = [
    { path: '/', component: Home, name: 'home' },
    { path: '*', component: NotFound, name: 'not.found' },
];

const router = new VueRouter({
    mode: 'history',
    base: '/en/v1',
    routes
});
export default router;
