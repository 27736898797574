class Auth {
    constructor(user) {
        this.user = (typeof user === "object")? user:{};
    }
    setUser(user) {
        this.user = (typeof user === "object")? user:{};
    }
    getUser() {
        return this.user;
    }
    setDomains(domains) {
        this.domains = (typeof domains === "object")? domains:[];
    }
    getDomains() {
        return this.domains;
    }
    isLoggedIn() {
        return (typeof this.user === "object" && this.user.hasOwnProperty("id") && this.user.id);
    }
    getId() {
        return (typeof this.user === "object" && this.user.hasOwnProperty("id"))? this.user.id:null;
    }
    getIdentifier() {
        return (typeof this.user === "object" && this.user.hasOwnProperty("identifier"))? this.user.identifier:null;
    }
    getUsername() {
        return (typeof this.user === "object" && this.user.hasOwnProperty("identifier"))? this.user.identifier:null;
    }
    getEmail() {
        return (typeof this.user === "object" && this.user.hasOwnProperty("email"))? this.user.email:null;
    }
    getOneDomain() {
        return !_.isEmpty(this.domains)? _.first(this.domains):null;
    }
}

export default {
    install(Vue, options) {
        Vue.prototype.$Auth = new Auth();
    }
}