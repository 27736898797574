var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-form" }, [
    _c(
      "div",
      {
        ref: "toggle",
        staticClass: "select-input",
        attrs: {
          tabindex: _vm.disabled ? -1 : _vm.tabindex,
          disabled: _vm.disabled
        },
        on: {
          click: _vm.toggle,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.onKeydownMain($event)
          }
        }
      },
      [
        _vm.multiple
          ? _c("div", { staticClass: "select-text" }, [
              _vm.value.length
                ? _c(
                    "div",
                    { staticClass: "select-tags" },
                    _vm._l(_vm.value, function(x, i) {
                      return _c("div", { staticClass: "tag tag-primary" }, [
                        _c("span", { staticClass: "tag-text" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(x["value"]) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "fa fa-times tag-close",
                          on: {
                            mousedown: function($event) {
                              $event.preventDefault()
                              return _vm.remove(x, i)
                            }
                          }
                        })
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._v(_vm._s(_vm.placeholder))])
            ])
          : _c("div", { staticClass: "select-text" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.value && _vm.value["value"]
                      ? _vm.value["value"]
                      : "Select"
                  ) +
                  "\n        "
              )
            ]),
        _vm._v(" "),
        _vm.removable && _vm.value && _vm.value.id
          ? _c("span", {
              staticClass: "select-remove icon icon-trash-a",
              on: { click: _vm.removeVal }
            })
          : _c("span", {
              class: [
                "select-icon icon icon-arrow-" +
                  (_vm.showDropdown ? "up-b" : "down-b")
              ]
            })
      ]
    ),
    _vm._v(" "),
    _vm.showDropdown
      ? _c("div", { staticClass: "select-dropdown" }, [
          _c("div", { staticClass: "select-inner" }, [
            _c("div", { staticClass: "select-search-wrap" }, [
              _c("input", {
                ref: "search",
                staticClass: "select-search",
                attrs: { type: "text", placeholder: "Search..." },
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onDownKey($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onEnter($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onUpKey($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape"
                        ])
                      ) {
                        return null
                      }
                      return _vm.onBlur($event)
                    }
                  ],
                  input: _vm.onSearch,
                  blur: _vm.onBlur
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "items", staticClass: "select-items" },
              _vm._l(_vm.availableOptions, function(option, i) {
                return _c(
                  "div",
                  {
                    class: [
                      "select-item",
                      _vm.selectIndex === i ? "select-active" : ""
                    ],
                    on: {
                      mouseover: function($event) {
                        $event.preventDefault()
                        return _vm.onMouse(i)
                      },
                      mousedown: function($event) {
                        $event.preventDefault()
                        return _vm.select(option)
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(option["value"]))])]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }