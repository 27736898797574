<template>
    <nav aria-label="Page navigation">
        <ul class="pagination font-s-12">
            <li class="page-item pagination-page-nav" :class="!hasFirst? disabledClass:''"><a class="page-link" @click="selectPage(1)">First</a></li>
            <li class="page-item pagination-page-nav" :class="!hasPrevious? disabledClass:''"><a class="page-link" @click="previousPage">Previous</a></li>
            <li class="page-item pagination-page-nav" v-if="hasPageRange"  v-for="page in pagerRange" :class="getActiveClass(page)">
                <a class="page-link" @click="selectPage(page)">{{ page }}</a>
            </li>
            <li class="page-item pagination-page-nav" :class="!hasNext? disabledClass:''"><a class="page-link" @click="nextPage()">Next</a></li>
            <li class="page-item pagination-page-nav" :class="!hasLast? disabledClass:''"><a class="page-link" @click="selectPage(pager.last_page)">Last</a></li>
            <li class="page-item pagination-page-nav ml-auto">
                <div class="input-group mb-2 mr-sm-2">
                    <input type="text" class="form-control" ref="goto" :value="currentPage" placeholder="Page No.">
                    <div class="input-group-append">
                        <button class="input-group-text" @click="goto">Go</button>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            pager: {
                type: Object,
                default: () => {
                    return {
                        current_page: 1,
                        from: 1,
                        path: 0,
                        last_page: 1,
                        per_page: 0,
                        to: 0,
                        total: 0
                    };
                }
            },
            adjacent: {
                type: Number,
                default: 5
            },
            activeClass: {
                type: String,
                default: 'active'
            },
            disabledClass: {
                type: String,
                default: 'disabled'
            },
        },
        computed:{
            currentPage() {
                return this.pager.current_page;
            },
            totalPages() {
                return this.pager.last_page;
            },
            totalItems() {
                return this.pager.total;
            },
            itemsCount() {
                return (parseInt(this.end) - parseInt(this.start)) + 1;
            },
            hasPrevious(){
                return this.currentPage > 1;
            },
            hasFirst(){
                return this.currentPage > 1;
            },
            hasNext(){
                return this.currentPage < this.totalPages;
            },
            hasLast(){
                return this.currentPage < this.totalPages;
            },
            hasPageRange() {
                return !_.isEmpty(this.pagerRange);
            },
            start() {
                return this.pager.from;
            },
            end() {
                return this.pager.to;
            },
            pagerRange() {
                let pager = [];
                if(!this.totalPages)
                    return pager;
                pager = this.range(1, this.totalPages);
                if ((this.adjacent = Math.floor(this.adjacent / 2) * 2 + 1) >= 1)
                {
                    let min = Math.min(pager.length - this.adjacent, parseInt(this.currentPage) - Math.ceil(this.adjacent / 2));
                    pager = pager.slice(
                        Math.max(
                            0,
                            min
                        ),
                        this.adjacent + ((min<0)? 0:min)
                    );
                }
                return pager;
            }
        },
        methods:{
            isActive(page) {
                return page === this.currentPage;
            },
            getActiveClass(page) {
                return this.isActive(page)? this.activeClass:'';
            },
            previousPage () {
                if(this.hasPrevious)
                    this.selectPage((this.currentPage - 1));
            },
            nextPage () {
                if(this.hasNext)
                    this.selectPage((this.currentPage + 1));
            },
            goto () {
                let page = Number(this.$refs.goto.value);
                if(!_.isUndefined(page) && _.isNumber(page)){
                    if(page >= 1 && page <= this.totalPages && page !== this.currentPage){
                        this.selectPage(page);
                    }
                }
            },
            selectPage (page) {
                if (page === '...') {
                    return;
                }
                this.$emit('pagination-change-page', page);
            },
            range (low, high, step) {

                var matrix = [];
                var iVal;
                var endval;
                var plus;
                var walker = step || 1;
                var chars = false;

                if (!isNaN(low) && !isNaN(high)) {
                    iVal = low;
                    endval = high;
                } else if (isNaN(low) && isNaN(high)) {
                    chars = true;
                    iVal = low.charCodeAt(0);
                    endval = high.charCodeAt(0)
                } else {
                    iVal = (isNaN(low) ? 0 : low);
                    endval = (isNaN(high) ? 0 : high);
                }

                plus = !(iVal > endval);
                if (plus) {
                    while (iVal <= endval) {
                        matrix.push(((chars) ? String.fromCharCode(iVal) : iVal));
                        iVal += walker;
                    }
                } else {
                    while (iVal >= endval) {
                        matrix.push(((chars) ? String.fromCharCode(iVal) : iVal));
                        iVal -= walker;
                    }
                }

                return matrix;
            }
        }
    }
</script>

<style scoped>

</style>
