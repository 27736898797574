<template>
    <div class="container-fluid">
        <div class="row">
            <div class="container">

                    <div class="address-book-container">
                        <div class="address-book card mb-4">
                            <transition name="fade">
                                <div class="loading-bar" v-show="loading">
                                    <div class="progress-line"></div>
                                </div>
                            </transition>
                            <div class="card-header">
                                <h4 class="mb-0">Search for your nearest YoraHome buddy</h4>
                            </div>
                            <div class="card-body">
                                <div class="search-control">
                                    <label>Enter your address or postal code</label>
                                    <gmap-autocomplete class="form-control gmap-search-bar" @place_changed="search" placeholder=""></gmap-autocomplete>
                                </div>

                            </div>
                        </div>

                    </div>




                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Map</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">List</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <GoogleMap @markerClicked="showMarker" :markers="items" :marker="center"/>
                        <div class="map-legend p-3 ps-1" style="background-color:#f1f1f1;">
                            <div class="buddy-location d-flex justify-content-start">
                                <div class="media mr-4">
                                    <img src="https://cdn.shopify.com/s/files/1/2640/0786/files/red-marker.svg?v=1658163778" width="20" class="mr-3" alt="Yorahome Buddies Location">
                                    <div class="media-body">
                                        <h6 class="mt-0">YoraHome Buddies Addresses</h6>
                                    </div>
                                </div>
                                <div class="media">
                                    <img src="https://cdn.shopify.com/s/files/1/2640/0786/files/black-marker.svg?v=1658163778" class="mr-3" alt="Yorahome Buddies Location" width="20">
                                    <div class="media-body">
                                        <h6 class="mt-0">Your Location</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <ul class="address-list list-group mt-4">
                            <li class="address-item list-group-item" v-for="item in items" >
                                <h5 class="text-uppercase address-title">{{ item.title }}</h5>
                                <p class="address-address">{{ item.full_address }}</p>
                                <a href="javascript:;" class="" @click="showMarker(item)">View details and directions</a>
                            </li>
                        </ul>
                        <div class="mt-4" v-if="!_.isEmpty(items)" >
                            <pagination :pager="meta" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>




            </div>
            <modal
                name="marker"
                :height="'auto'"
                :adaptive="true"
                :classes="['single-marker-modal modal-xl']"
                @closed="modalClosed"
            >
                <template v-if="selectedMarker">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{selectedMarker.title}}</h5>
                            <button type="button" class="close" @click="$modal.hide('marker')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-form col-md-5">
                                    <div>
                                        <h5 class="display_name">{{selectedMarker.display_name}}</h5>
                                        <p>{{selectedMarker.address}}<br>
                                        {{selectedMarker.state}}, {{selectedMarker.country}}</p>
                                        <p><a :href="`tel:${selectedMarker.phone}`">{{selectedMarker.phone}}</a></p>

                                    </div>
                                    <div class="alert alert-success" v-if="success">{{this.success}}</div>
                                    <form v-if="!success" @submit.prevent="submitForm" novalidate>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label mb-10" for="name">Name</label>
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        class="form-control"
                                                        name="name"
                                                        :value="_.get(form, 'name', null)"
                                                        @input="updateInput"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="'name'"></input-error>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label mb-10" for="email">Email</label>
                                                    <input
                                                        id="email"
                                                        type="text"
                                                        class="form-control"
                                                        name="email"
                                                        :value="_.get(form, 'email', null)"
                                                        @input="updateInput"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="'email'"></input-error>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label mb-10" for="phone">Phone</label>
                                                    <input
                                                        id="phone"
                                                        type="text"
                                                        class="form-control"
                                                        name="phone"
                                                        :value="_.get(form, 'phone', null)"
                                                        @input="updateInput"
                                                        required
                                                    >
                                                    <input-error :errors="errors" :name="'phone'"></input-error>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label mb-10" for="message">Message</label>
                                                    <textarea
                                                        id="message"
                                                        type="text"
                                                        class="form-control"
                                                        name="message"
                                                        :value="_.get(form, 'message', null)"
                                                        @input="updateInput"
                                                    ></textarea>
                                                    <input-error :errors="errors" :name="'message'"></input-error>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group text-right">
                                                    <vue-button-spinner
                                                        class="btn btn-primary"
                                                        :isLoading="loading"
                                                        :disabled="loading"
                                                    >
                                                        Submit
                                                    </vue-button-spinner>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-map col-md-7">
                                    <GoogleMap :info-type="'info'" :markers="[selectedMarker]" :marker="selectedMarker"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
import GoogleMap from "./Common/GoogleMap";
import Pagination from "./Common/Pagination";
import VModal from 'vue-js-modal';
import Vue from 'vue';
Vue.use(VModal);
export default {
    components: {
        GoogleMap,
        Pagination
    },
    data() {
        return {
            success: null,
            errors: {},
            form: {},
            center: {
                title: "",
                position: {
                    lat: 44.58504322216937,
                    lng: -100.3781779598541
                },
                full_address: ""
            },
            selectedMarker: null,
            items: [],
            meta: {},
            loading: false,
            query: {
                address: null,
                page: 1
            },
            fetch: 0
        }
    },
    created() {
        this.fetchMarkers();
    },
    watch: {
        fetch: {
            handler(fetch) {
                this.fetchMarkers();
            }
        }
    },
    methods: {
        updateInput(e) {
            let key = e.target.getAttribute("name");
            let value = e.target.value;
            _.set(this.form, key, value);
        },
        async submitForm(){
            try {
                this.errors = {};
                this.loading = true;
                const response = await axios.post(`/api/markers/${this.selectedMarker.id}`, this.form);
                this.form = {};
                this.success = "Request Submitted Successfully";
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.errors =  _.get(e.response.data, 'errors', {})
            }
        },
        showMarker(marker) {
            this.selectedMarker = marker;
            this.$modal.show('marker')
        },
        modalClosed() {
            this.selectedMarker = null;
            this.errors = {};
            this.success = null;
        },
        search(e) {
            if(e) {
                this.center = {
                    title: e.formatted_address,
                    position: {
                        lat: e.geometry.location.lat(),
                        lng: e.geometry.location.lng(),
                    },
                    full_address: e.formatted_address
                };
                this.query.address = e.formatted_address;
                this.$set(this.query, "page", 1);
                this.$set(this.$data, "fetch", this.fetch+1);
            }
        },
        getResults(page = 1) {
            this.$set(this.query, "page", page);
            this.$set(this.$data, "fetch", this.fetch+1);
        },
        async fetchMarkers()
        {
            try{
                this.loading = true;
                const response = await axios.get('/api/markers', {params: this.query});
                this.items = _.get(response.data, "data", []);
                this.meta = _.get(response.data, "meta", {});
                /*if(this.items) {
                    this.center = this.items[0].position;
                }*/
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.$awn.info("OOPS! Error Occurred.")
            }
        }
    }
}
</script>

<style scoped>

</style>
