<template>
    <transition name="fade">
        <div class="loading-bar" v-show="show">
            <div class="progress-line"></div>
        </div>
    </transition>
</template>
<script>
    export default {
        props:{
            color: null,
            bgColor: null
        },
        data() {
            return {
                show: false
            }
        },
        methods: {
            start() {
                this.show = true;
            },
            finish() {
                var _self = this;
                setTimeout(function(){
                    _self.show = false;
                }, 500);
            }
        }
    }
</script>