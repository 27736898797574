var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card shadow mb-4 filterable" },
    [
      _vm._t("card-header", [
        _c(
          "div",
          { staticClass: "card-header py-3" },
          [
            _vm._t("title"),
            _vm._v(" "),
            _vm._t("filter-nav", null, {
              sortingClass: _vm.sortingClass,
              sortData: _vm.sortData
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._t(
        "loader",
        [_c("loader-bar", { attrs: { loading: _vm.loading } })],
        { loading: _vm.loading }
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm._t("alert", [
            _vm.hasErrors
              ? _c("div", { staticClass: "alert alert-danger mt-1" }, [
                  _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                  _vm._v(" OOPS! Error Occurred.\n            ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._t("filterable-selectors", [
            _c(
              "div",
              { staticClass: "pb-3 collapse", attrs: { id: "collapseFilter" } },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._t("exportables", [
                      !_vm._.isEmpty(_vm.exportable) &&
                      _vm.exportEndpoint &&
                      _vm.showExportable &&
                      !_vm.hide
                        ? [_vm._m(0)]
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm._t("filterables", [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "filters-title pt-1 pb-2 mb-2" },
                          [
                            _c("span", [_vm._v("Records matching")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.query.filter_match,
                                    expression: "query.filter_match"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.query,
                                      "filter_match",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "and" } }, [
                                  _vm._v("All")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "or" } }, [
                                  _vm._v("Any")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("Of the following")]),
                            _vm._v(" "),
                            !_vm._.isEmpty(_vm.exportable) &&
                            _vm.exportEndpoint &&
                            !_vm.hide
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-info text-white",
                                    on: {
                                      click: function($event) {
                                        _vm.showExportable = !_vm.showExportable
                                      }
                                    }
                                  },
                                  [
                                    _vm.showExportable
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fas fa-box"
                                          }),
                                          _vm._v(" Hide")
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.showExportable
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fas fa-box-open"
                                          }),
                                          _vm._v(" Show")
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "filters filter-conditions" },
                          _vm._l(_vm.filterCandidates, function(f, i) {
                            return _c(
                              "div",
                              { staticClass: "filters-item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "filters-column" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        staticClass: "form-control",
                                        on: {
                                          change: function($event) {
                                            return _vm.onColumnSelect(
                                              f,
                                              i,
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("Select Filter")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(_vm.filterGroups, function(
                                          group
                                        ) {
                                          return _c(
                                            "optgroup",
                                            { attrs: { label: group.title } },
                                            _vm._l(group.filters, function(x) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: JSON.stringify(x),
                                                    selected:
                                                      f.column &&
                                                      x.name === f.column.name
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(x.title) +
                                                      "\n                                                "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("input-error", {
                                      attrs: {
                                        errors: _vm.errors,
                                        name: "f." + i + ".column"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                f.column
                                  ? _c(
                                      "div",
                                      { staticClass: "filters-operator" },
                                      [
                                        _c(
                                          "select",
                                          {
                                            staticClass: "form-control",
                                            on: {
                                              change: function($event) {
                                                return _vm.onOperatorSelect(
                                                  f,
                                                  i,
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          _vm._l(
                                            _vm.fetchOperators(f),
                                            function(y) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: JSON.stringify(y),
                                                    selected:
                                                      f.operator &&
                                                      y.name === f.operator.name
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(y.name) +
                                                      "\n                                            "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c("input-error", {
                                          attrs: {
                                            errors: _vm.errors,
                                            name: "f." + i + ".operator"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                f.column && f.operator
                                  ? [
                                      f.operator.component === "single"
                                        ? _c(
                                            "div",
                                            { staticClass: "filters-full" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: f.query_1,
                                                    expression: "f.query_1"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: f.placeholder
                                                },
                                                domProps: { value: f.query_1 },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      f,
                                                      "query_1",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-error", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  name: "f." + i + ".query_1"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      f.operator.component === "lookup"
                                        ? _c(
                                            "div",
                                            { staticClass: "filters-full" },
                                            [
                                              _c("x-tag-input", {
                                                attrs: {
                                                  value: f.query_1,
                                                  resource: f.column.resource,
                                                  column:
                                                    f.column.column ||
                                                    f.column.name,
                                                  name:
                                                    f.column.column ||
                                                    f.column.name,
                                                  multiple: ""
                                                },
                                                on: {
                                                  input: function(value) {
                                                    f.query_1 = value
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-error", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  name: "f." + i + ".query_1"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      f.operator.component === "static_lookup"
                                        ? _c(
                                            "div",
                                            { staticClass: "filters-full" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  value: f.query_1,
                                                  options: _vm.setOptions(
                                                    f.column.options
                                                  ),
                                                  label: "name",
                                                  multiple: ""
                                                },
                                                on: {
                                                  input: function(value) {
                                                    f.query_1 = value
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input-error", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  name: "f." + i + ".query_1"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : f.operator.component === "dual"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_1,
                                                      expression: "f.query_1"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_1
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_1",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_2"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_2,
                                                      expression: "f.query_2"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_2
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_2",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "datetime_1"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_1,
                                                      expression: "f.query_1"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_1
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_1",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_2"
                                              },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: f.query_2,
                                                        expression: "f.query_2"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          f,
                                                          "query_2",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "hours"
                                                        }
                                                      },
                                                      [_vm._v(_vm._s("hours"))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "days" }
                                                      },
                                                      [_vm._v(_vm._s("days"))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "months"
                                                        }
                                                      },
                                                      [_vm._v(_vm._s("months"))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "years"
                                                        }
                                                      },
                                                      [_vm._v(_vm._s("years"))]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "datetime_2"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_2"
                                              },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: f.query_1,
                                                        expression: "f.query_1"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          f,
                                                          "query_1",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "yesterday"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("yesterday")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "today"
                                                        }
                                                      },
                                                      [_vm._v(_vm._s("today"))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "tomorrow"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("tomorrow")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "last_month"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("last_month")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "this_month"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("this_month")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "next_month"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("next_month")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "last_year"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("last_year")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "this_year"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("this_year")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "next_year"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("next_year")
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "datetime_3"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_1,
                                                      expression: "f.query_1"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "date",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_1
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_1",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "datetime_4"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_1,
                                                      expression: "f.query_1"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_1
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_1",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_2"
                                              },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: f.query_2,
                                                        expression: "f.query_2"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          f,
                                                          "query_2",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "hours"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("hours") +
                                                            " " +
                                                            _vm._s("ago")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "days" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("days") +
                                                            " " +
                                                            _vm._s("ago")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "months"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("months") +
                                                            " " +
                                                            _vm._s("ago")
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "years"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s("years") +
                                                            " " +
                                                            _vm._s("ago")
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "datetime_5"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_1,
                                                      expression: "f.query_1"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "date",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_1
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_1",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_2"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: f.query_2,
                                                      expression: "f.query_2"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "date",
                                                    placeholder: f.placeholder
                                                  },
                                                  domProps: {
                                                    value: f.query_2
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        f,
                                                        "query_2",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : f.operator.component === "toggle"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "filters-query_1"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "filter-toggle"
                                                  },
                                                  [
                                                    _c("x-switch", {
                                                      model: {
                                                        value: f.query_1,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            f,
                                                            "query_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "f.query_1"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("input-error", {
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    name: "f." + i + ".query_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                f
                                  ? _c(
                                      "div",
                                      { staticClass: "filters-remove-wrap" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "filters-remove text-danger",
                                            attrs: { disabled: _vm.loading },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeFilter(f, i)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash-alt"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.appliedFilters.length || _vm.showFilterControls
                          ? _c("div", { staticClass: "filters-control" }, [
                              _c(
                                "div",
                                { staticClass: "filters-control-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary",
                                      attrs: { disabled: _vm.loading },
                                      on: { click: _vm.addFilter }
                                    },
                                    [_c("i", { staticClass: "fa fa-plus" })]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.appliedFilters.length
                                ? _c(
                                    "div",
                                    { staticClass: "filters-control-item" },
                                    [
                                      _c("div", {
                                        staticClass: "filters-control-item-line"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary",
                                          attrs: { disabled: _vm.loading },
                                          on: { click: _vm.resetFilter }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-sync-alt"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "filters-control-item" },
                                [
                                  _c("div", {
                                    staticClass: "filters-control-item-line"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary",
                                      attrs: { disabled: _vm.loading },
                                      on: { click: _vm.applyFilter }
                                    },
                                    [_c("i", { staticClass: "fa fa-filter" })]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.exportEndpoint && _vm.appliedFilters.length
                                ? _c(
                                    "div",
                                    { staticClass: "filters-control-item" },
                                    [
                                      _c("div", {
                                        staticClass: "filters-control-item-line"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary",
                                          attrs: { disabled: _vm.loading },
                                          on: { click: _vm.applyExport }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-file-export"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm._t("content", null, {
                items: _vm.items,
                remove: _vm.remove,
                sortingClass: _vm.sortingClass,
                sortData: _vm.sortData
              }),
              _vm._v(" "),
              _vm._t(
                "paging",
                [
                  !_vm._.isEmpty(_vm.items.data)
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("pagination", {
                            attrs: { pager: _vm.items.meta },
                            on: { "pagination-change-page": _vm.getResults }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                { items: _vm.items, getResults: _vm.getResults }
              )
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "filters-title pt-4 pb-2 mb-2" }, [
        _c("span", [_vm._v("Select fields to export of the following")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }